<template>
<Loading :isLoading="isLoading"/>
  <div v-if="rulesLoaded">
        <div class="w-full">
            <p class="text-center font-bold text-3xl" style="color:#810042">Captura un período para pago de comisiones</p>
        </div>

        <div class="mt-20">
            <table class="width200">
                <thead>
                    <tr>
                        <th>Nombre del Perido</th>
                        <th>Fecha Inicio</th>
                        <th>Fecha Fin</th>
                        <th></th>
                        <th>Creado por</th>
                        <th>Fecha de registro</th>
                    </tr>
                </thead>
                <tbody>
                    
                    <tr v-for="(periodPayment,index) of  _listOfPeriodPatment" :key=index >
                        <td><label :id="'label_period_name_capture_period_'+index" >{{periodPayment.name}}</label> </td>
                        <td><label :id="'label_start_date_capture_period_'+index">{{periodPayment.start_date}}</label></td>
                        <td><label :id="'label_end_date_capture_period_'+index">{{periodPayment.end_date}}</label></td>
                        <td>
                            <button class="p-3 icon_edit_record_capture_period" style="outline:0" :id="'icon_edit_record_capture_period'+index" @click="OpenShowEditModal(periodPayment)"
                            v-if="periodPayment.closing_date===null"></button>
                            <button class="p-3 icon_edit_record_capture_period_disable" style="outline:0;" :id="'icon_edit_record_capture_period'+index"
                            v-if="periodPayment.closing_date!=null"></button>
                        </td>
                        <td> <label :id="'label_created_by_capture_period_'+index"></label> {{periodPayment.registered_by}}</td>
                        <td> <label :id="'label_registration_date_capture_period_'+index"></label> {{$filters.formatDate(periodPayment.created_at)}}</td>
                    </tr>

                </tbody>
            </table>

            <div v-show="hidePagination">
                <Pagination ref="PaginationRef" :totalRowQuerry="totalRowQuerry" :maxQueryItems="10" v-on:change-pagination="getPeriodPayment($event)" />
            </div>

        </div>

        <div style="border:1px solid #810042;border-radius:5px" class="px-10 pt-10" v-if="_showAddNewPeriod">

            <div class="w-full">
                <p style="color:#810042" class="font-bold text-2xl">Nuevo Periodo</p>
            </div>

            <div class="w-full sm:flex mt-5">
                <div class="sm:mr-5 sm:w-1/3  2xl:w-1/4 ">
                    <p>Nombre del Periodo:</p>
                    <input type="text" class="shadow-md border-2 rounded-lg py-2 w-full px-5" style="outline:0" id="input_period_name_capture_period" 
                    :maxlength="51" v-model="v$.name.$model" :class="{'invalid-form':v$.name.$error, 'valid-form':!v$.name.$invalid}">

                    <div v-for="(error,index) of v$.name.$errors" :key="index">
                        <div class="errors" :id="'error_name_'+index">{{ error.$message }}</div>
                    </div>
               </div>
               <div class="sm:mx-5 mt-4 sm:mt-0 sm:w-1/3 2xl:w-1/4" >
                    <p>Fecha Inicio:</p>
                    <Dates
                        v-on:get-complete-date-selected="getResponseDateStart($event)"
                        :idYear="'select_start_date_year_capture_period'"
                        :idMonth="'select_start_date_month_capture_period'"
                        :idDay="'select_start_date_day_capture_period'"
                        @click="v$.start_date.$touch"
                        :class="{'invalid-form rounded-lg':v$.start_date.$error, 'valid-form rounded-lg':!v$.start_date.$invalid}"
                    />

                    <div v-for="(error,index) of v$.start_date.$errors" :key="index">
                        <div class="errors" :id="'error_name_'+index">{{ error.$message }}</div>
                    </div>
               </div>
               <div class="sm:mx-5 mt-4 sm:mt-0 sm:w-1/3  2xl:w-1/4">
                    <p>Fecha Fin:</p>
                    <Dates
                        v-on:get-complete-date-selected="getResponseDateFinish($event)"
                        :idYear="'select_end_date_year_capture_period'"
                        :idMonth="'select_end_date_month_capture_period'"
                        :idDay="'select_end_date_day_capture_period'"
                        @click="v$.end_date.$touch"
                        :class="{'invalid-form rounded-lg':v$.end_date.$error, 'valid-form rounded-lg':!v$.end_date.$invalid}"
                    />
                    <div v-for="(error,index) of v$.end_date.$errors" :key="index">
                        <div class="errors" :id="'error_name_'+index">{{ error.$message }}</div>
                    </div>
                    
               </div>
               <div class="w-full sm:w-1/3 mb-10 mb-0">
                    <button class=" rounded-lg py-5 w-full px-5 mt-5" id="select_save_new_period_capture_period" @click="saveNewPeriodPayment()" >Guardar</button>
               </div>
            </div>
            
        </div>

        <div class="mt-10 sm:text-right gap-x-10">
            <button id="button_export_report_capture_period" class="px-30 py-2 2xl:py-5 w-full sm:mx-10 sm:w-1/4" @click="generateCSV()">Exportar Reporte</button>
            <button id="button_added_period_capture_period" class="px-30 py-2 2xl:py-5 w-full sm:w-1/4 mt-5 sm:mt-0" @click="showAddNewPeriod()"> 
                <a v-if="_showAddNewPeriod==false" style="text-decoration: none;list-style: none;color:#ffffff">Agregar Periodo </a> 
                <a v-if="_showAddNewPeriod" style="text-decoration: none;list-style: none;color:#ffffff">Cerrar </a>
            </button>
        </div>

        <Modal v-if="showEditModal" :heightContainer="'auto'" :widthContainer="'30%'">
          <template v-slot:header>
            <p @click="CloseShowEditModal()"  v-on:keyup.esc="CloseShowEditModal()" ><img src="@/assets/close.svg" height="20" width="20" style="float: right;"></p>
            <p class="text-center text-2xl" style="color:#810042" id="label_product _history_configure_commission" >Editar Periodo</p>
          </template>
          <template v-slot:body >
              <div>
                  <label>Nombre del Periodo</label>
                   <input type="text" class="outline-none shadow-md border-2 rounded-lg py-2 w-full px-3 focus:border-cherryColor inputText" id="input_period_name_capture_period" 
                    :maxlength="51" v-model="v2$.name.$model" :class="{'invalid-form ':v2$.name.$error, 'valid-form':!v2$.name.$invalid}">

                    <div v-for="(error,index) of v2$.name.$errors" :key="index">
                        <div class="errors" :id="'error_name_'+index">{{ error.$message }}</div>
                    </div>
              </div>

              <div class="sm:flex sm:space-x-5">
               <div class="w-full mt-5" @click="v2$.start_date.$touch">
                    <label>Fecha Inicio:</label>
                    <Dates
                        v-on:get-complete-date-selected="getResponseDateStartEdit($event)"
                        :year="date_Start.year"
                        :month="date_Start.month"
                        :day="date_Start.day"
                        :idYear="'select_start_date_year_capture_period'"
                        :idMonth="'select_start_date_month_capture_period'"
                        :idDay="'select_start_date_day_capture_period'"
                        @click="v2$.start_date.$touch"
                        :class="{'invalid-form rounded-lg':v2$.end_date.$error, 'valid-form rounded-lg':!v2$.end_date.$invalid}"
                    />
                    <div v-for="(error,index) of v2$.end_date.$errors" :key="index">
                        <div class="errors" :id="'error_name_'+index">{{ error.$message }}</div>
                    </div>
               </div>
               <div class="w-full mt-5" @click="v2$.end_date.$touch">
                    <label>Fecha Fin:</label>
                    <Dates
                        v-on:get-complete-date-selected="getResponseDateFinishEdit($event)"
                        :year="date_Finish.year"
                        :month="date_Finish.month"
                        :day="date_Finish.day"
                        :idYear="'select_end_date_year_capture_period'"
                        :idMonth="'select_end_date_month_capture_period'"
                        :idDay="'select_end_date_day_capture_period'"
                        @click="v2$.end_date.$touch"
                        :class="{'invalid-form rounded-lg':v2$.end_date.$error, 'valid-form rounded-lg':!v2$.end_date.$invalid}"
                    />
                    
                    <div v-for="(error,index) of v2$.end_date.$errors" :key="index">
                        <div class="errors" :id="'error_name_'+index">{{ error.$message }}</div>
                    </div>            
               </div>

            </div>
          </template>
          <template v-slot:footer>
              <div class="w-full sm:flex sm:space-x-5 mt-5">
                <button class="rounded-lg py-2 w-full sm:w-1/2 px-5" id="button_Cancel_edition_period_capture_period" @click="CloseShowEditModal()" >Cancelar</button>
                <button class="rounded-lg py-2 w-full sm:w-1/2 px-5 mt-5 sm:mt-0" id="button_save_edition_period_capture_period" @click="saveEditContent()">Guardar</button>
              </div>
          </template>
        </Modal>



  </div>
</template>


<script>
import('@/assets/css/stylesheet.css');

import Modal from '@/components/Modal/Modal.vue';
import Dates from '@/components/Forms/Selects/DateFuture';
import { onBeforeMount, reactive, ref } from 'vue-demi';
import useVuelidate from '@vuelidate/core';
import { helpers, requiredIf } from '@vuelidate/validators';
import validations from '@/helpers/validations';
import moment from 'moment';
import Swal from 'sweetalert2';
import CommissionScheme from "@/classes/CommissionScheme"
import {getTokenDecoden} from "@/helpers/tokenauth";
import Loading from '@/components/Loading/VueLoading.vue'
import Pagination from "@/components/Forms/Pagination/Pagination.vue"

export default {
  name: "RecordPeriodPaymentCommissions",
  components:{
    Modal,
    Dates,
    Loading,
    Pagination
  },
  setup(){
    let isLoading= ref(false);
    let _showAddNewPeriod=ref(false);
    let rulesValidationsJson=ref();
    let rulesLoaded=ref(false);
    let _listOfPeriodPatment=ref([]);
    let totalRowQuerry=ref(); //total de filas que hay en la consulta
    const PaginationRef=ref(null);
    let hidePagination=ref(true);
    let _classCommissions= new CommissionScheme();
    let showEditModal=ref(false);
    let date_Start=ref({
        day:"",
        month:"",
        year:""
    });
    let date_Finish=ref({
        day:"",
        month:"",
        year:""
    });
    let _recordPeriod=ref({
        token_auth:"", 
        name:"",
        start_date:"",
        end_date:"",
        closing_date:"",
        users_id:""
    });
    let _editPeriod=ref({
        token_auth:"",
        sale_commission_payment_period_id:"",
        name:"",
        start_date:"",
        end_date:"",
        closing_date:"",
        users_id:""
    });
    let rulesValidations=reactive({
        token_auth:"", 
        name:{
            required:helpers.withMessage("El campo Nombre del Periodo es obligatorio.",requiredIf(function(){return rulesValidationsJson.value.name.required})),
            minlength:helpers.withMessage(()=>{return "El campo Nombre del Periodo debe tener mínimo  "+ rulesValidationsJson.value.name.minLength+" caracter."},(value)=>{return rulesValidationsJson.value.name.minLength<=value.length}),
            maxlenghvalue:helpers.withMessage(()=>{return "El campo Nombre del Periodo debe tener máximo "+ rulesValidationsJson.value.name.maxLength +" caracteres."},(value)=>{return rulesValidationsJson.value.name.maxLength>=value.length}),
            isAlphaNumeric:helpers.withMessage("El campo Nombre del Periodo no admite caracteres especiales.",(value)=>value != ""? new RegExp(/^[a-zA-ZÀ-ÿ-0-9 ]+$/g).test(value): true)
        },
        start_date:{
            required:helpers.withMessage("El campo Fecha Inicio es obligatorio.",requiredIf(function(){return true})),
            maxDateToday:helpers.withMessage("El campo Fecha Inicio tiene que ser mayor a la fecha actual.",function (value){
                let dateSelect =moment(value).format('YYYY-MM-DD');
                let dateStart=moment(new Date()).format('YYYY-MM-DD');
                if(dateStart>dateSelect){
                    return false
                }
                return true
            }),
            differentTo:helpers.withMessage("El campo Fecha Inicio tiene que ser menor al campo Fecha Fin.",function (value){
                let dateSelect =moment(value).format('YYYY-MM-DD');
                let dateFinish=moment(_recordPeriod.value.end_date).format('YYYY-MM-DD');
                if(dateFinish< dateSelect){
                    return false
                }
                return true
            }),
        },
        end_date:{
            required:helpers.withMessage("El campo Fecha Fin es obligatorio.",requiredIf(function(){return true})),
            maxDateToday:helpers.withMessage("El campo Fecha Final tiene que ser mayor a la fecha actual.",function (value){
                let dateSelect =moment(value).format('YYYY-MM-DD');
                let dateStart=moment(new Date()).format('YYYY-MM-DD');
                if(dateStart>dateSelect){
                    return false
                }
                return true
            }),
            differentTo:helpers.withMessage("El campo Fecha Fin tiene que ser mayor al campo Fecha Inicio.",function (value){
                let dateSelect =moment(value).format('YYYY-MM-DD');
                let dateStart=moment(_recordPeriod.value.start_date).format('YYYY-MM-DD');
                if(dateStart> dateSelect){
                    return false
                }
                return true
            }),  
        },
        closing_date:"",
        users_id:""
    });
    let rulesValidationsEdit=reactive({
        token_auth:"",
        sale_commission_payment_period_id:"",
        name:{
            required:helpers.withMessage("El campo Nombre del Periodo es obligatorio.",requiredIf(function(){return rulesValidationsJson.value.name.required})),
            minlength:helpers.withMessage(()=>{return "El campo Nombre del Periodo debe tener mínimo  "+ rulesValidationsJson.value.name.minLength+" caracter."},(value)=>{return rulesValidationsJson.value.name.minLength<=value.length}),
            maxlenghvalue:helpers.withMessage(()=>{return "El campo Nombre del Periodo debe tener máximo "+ rulesValidationsJson.value.name.maxLength +" caracteres."},(value)=>{return rulesValidationsJson.value.name.maxLength>=value.length}),
            isAlphaNumeric:helpers.withMessage("El campo Nombre del Periodo no admite caracteres especiales.",(value)=>value != ""? new RegExp(/^[a-zA-ZÀ-ÿ-0-9 ]+$/g).test(value): true)
        },
        start_date:{
            required:helpers.withMessage("El campo Fecha Inicio es obligatorio.",requiredIf(function(){return true})),
            maxDateToday:helpers.withMessage("El campo Fecha Inicio tiene que ser mayor a la fecha actual.",function (value){
                let dateSelect =moment(value).format('YYYY-MM-DD');
                let dateStart=moment(new Date()).format('YYYY-MM-DD');
                if(dateStart>dateSelect){
                    return false
                }
                return true
            }),
            differentTo:helpers.withMessage("El campo Fecha Inicio tiene que ser menor al campo Fecha Fin.",function (value){
                let dateSelect =moment(value).format('YYYY-MM-DD');
                let dateFinish=moment(_editPeriod.value.end_date).format('YYYY-MM-DD');
                if(dateFinish< dateSelect){
                    return false
                }
                return true
            }),
        },
        end_date:{
            required:helpers.withMessage("El campo Fecha Fin es obligatorio.",requiredIf(function(){return true})),
            maxDateToday:helpers.withMessage("El campo Fecha Final tiene que ser mayor a la fecha actual.",function (value){
                let dateSelect =moment(value).format('YYYY-MM-DD');
                let dateStart=moment(new Date()).format('YYYY-MM-DD');
                if(dateStart>dateSelect){
                    return false
                }
                return true
            }),
            differentTo:helpers.withMessage("El campo Fecha Fin tiene que ser mayor al campo Fecha Inicio.",function (value){
                let dateSelect =moment(value).format('YYYY-MM-DD');
                let dateStart=moment(_editPeriod.value.start_date).format('YYYY-MM-DD');
                if(dateStart> dateSelect){
                    return false
                }
                return true
            }),  
        },
        users_id:""
    });
    let v$= useVuelidate(rulesValidations,_recordPeriod);
    let v2$= useVuelidate(rulesValidationsEdit,_editPeriod);

    onBeforeMount(()=>{
       getRulesValidations();
    });

    async function getRulesValidations(){
        const response=await validations.getStructureValidations("/json/commissions/recordperiodpayment.json");
        rulesValidationsJson.value = response.data.validations;
        rulesLoaded.value=true;
    }

    function showAddNewPeriod(){
        _recordPeriod.value={
            token_auth:"", 
            name:"",
            start_date:"",
            end_date:"",
            users_id:""
        };
        this.v$.$reset();
        _showAddNewPeriod.value=!_showAddNewPeriod.value;
    }

    function getResponseDateStart(value){
        _recordPeriod.value.start_date=value;
    }

    function getResponseDateFinish(value){
        _recordPeriod.value.end_date=value;
    }

    async function saveNewPeriodPayment(){
        isLoading.value = true;
        this.v$.$validate();
        try {
          if(!this.v$.$error){
            _recordPeriod.value.token_auth=sessionStorage.getItem("login");
            _recordPeriod.value.users_id=(getTokenDecoden()).id;
            let _response=await _classCommissions.save(_recordPeriod.value,`/${process.env.VUE_APP_BUSINESS_FONCABSA}/sale_commission_payment_period/register`);
            if(_response.data.response.has_errors){           
                let messages="";
                _response.data.errors.forEach(element => { messages+="<strong>■ "+element+".</strong><br>" });
                    Swal.fire({
                        title: "Aviso",
                        html:messages,
                        icon: "info",
                        confirmButtonText: "OK",
                        confirmButtonColor: '#FEB72B'
                    }); 
                        isLoading.value = false;
                        rulesLoaded.value=true;
                        return;
            }else{
                isLoading.value = false;
                    _recordPeriod.value={
                        token_auth:"", 
                        name:"",
                        start_date:"",
                        end_date:"",
                        users_id:""
                    };
                    this.v$.$reset();
                    _showAddNewPeriod.value=!_showAddNewPeriod.value;
                    PaginationRef.value.reloadTableQuery();
                    getPeriodPayment({
                        token_auth:sessionStorage.getItem("login"),
                        initial_row: 0,
                        final_row: 10
                    });
                    Swal.fire({
                        title: "El Nuevo Periodo para Pago Comision ha sido registrado correctamente.",
                        icon: "success",
                        confirmButtonText: "OK",
                        confirmButtonColor: '#FEB72B'
                    }); 
                    
                }
            }
        else{
            isLoading.value = false;
            Swal.fire({
                icon:"warning",
                title:"Cuidado!",
                text:"Favor de completar los campos solicitados correctamente.",
                confirmButtonColor:"#FEB72B"
            })
        }  
        } catch (error) {
            console.log(error)
            isLoading.value = false;
            Swal.fire({
                icon:"error",
                title:"Error!",
                text:"Ocurrio un error al momento de guardar los datos.",
                confirmButtonColor:"#FEB72B"
            }) 
        }
        setIdAttributeToAccepAndCancelButtonSwalFire("button_confirm_ok","")
    }

    async function getPeriodPayment(value){
        isLoading.value = true;
        try {
            const _response= await _classCommissions.get(value,"sale_commission_payment_period/get_payment_period_with_pagination");
            isLoading.value = false;
            if(_response.data.response.has_errors){
                let messages="";
                response.data.errors.forEach(element => {
                    messages+="<strong>■ "+element+".</strong><br>"
            });
            Swal.fire({
                title: "Aviso",
                html:response.data.response.message+"<br>"+messages,
                icon: "info",
                confirmButtonText: "OK",
                confirmButtonColor: '#FEB72B'
            });
            setIdAttributeToAccepAndCancelButtonSwalFire("button_confirm_ok","")
            }else{
                console.log(_response.data.response.sale_commission_payment_period_list.rows)
                _listOfPeriodPatment.value=_response.data.response.sale_commission_payment_period_list.rows;
                totalRowQuerry.value=await _response.data.response.sale_commission_payment_period_list.total_rows;
                hidePagination.value=totalRowQuerry.value<10?false:true;
                PaginationRef.value.setPagination(totalRowQuerry.value);
            }
        } catch (error) {
            console.log(error)
            isLoading.value = false;
            Swal.fire({
                icon:"warning",
                text:"Sin registros para mostrar.",
                title:"Aviso",
                confirmButtonText: "OK",
                confirmButtonColor: '#FEB72B'
            })
            setIdAttributeToAccepAndCancelButtonSwalFire("button_confirm_ok","")
        }
    }

    function OpenShowEditModal(value){
        var months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
        showEditModal.value=true;
        _editPeriod.value.name=value.name;
        _editPeriod.value.start_date=value.start_date;
        _editPeriod.value.end_date=value.end_date;
        _editPeriod.value.users_id=(getTokenDecoden()).id;
        _editPeriod.value.sale_commission_payment_period_id=value.id;

        date_Start.value.month = moment(value.start_date).format('MM').toString();
        date_Start.value.month = months[parseInt(date_Start.value.month)-1];
        date_Start.value.year=parseInt(moment(value.start_date).format('YYYY').toString())
        date_Start.value.day=moment(value.start_date).format('DD').toString();

        date_Finish.value.month = moment(value.end_date).format('MM').toString();
        date_Finish.value.month = months[parseInt(date_Finish.value.month)-1];
        date_Finish.value.year=parseInt(moment(value.end_date).format('YYYY').toString());
        date_Finish.value.day=moment(value.end_date).format('DD').toString();
    }

    function CloseShowEditModal(){
        showEditModal.value=false;
        _editPeriod.value={
            token_auth:"",
            sale_commission_payment_period_id:"",
            name:"",
            start_date:"",
            end_date:"",
            closing_date:"",
            users_id:""
        };
        this.v2$.$reset();
    }

    function getResponseDateStartEdit(value){
        _editPeriod.value.start_date=value;
    }

    function getResponseDateFinishEdit(value){
        _editPeriod.value.end_date=value;
    }

    async function saveEditContent(){
        this.v2$.$validate();
        try {
            if(!this.v2$.$error){
            _editPeriod.value.token_auth=sessionStorage.getItem("login");
            let _response=await _classCommissions.save(_editPeriod.value,`/${process.env.VUE_APP_BUSINESS_FONCABSA}/sale_commission_payment_period/update`);
            showEditModal.value=false;
            if(_response.data.response.has_errors){           
                    let messages="";
                    _response.data.errors.forEach(element => { messages+="<strong>■ "+element+".</strong><br>" });
                        Swal.fire({
                            title: "Aviso",
                            html:messages,
                            icon: "info",
                            confirmButtonText: "OK",
                            confirmButtonColor: '#FEB72B'
                        }); 
                            isLoading.value = false;
                            rulesLoaded.value=true;
                            return;
                }else{
                    isLoading.value = false;
                    this.v2$.$reset();
                    PaginationRef.value.reloadTableQuery();
                    getPeriodPayment({
                        token_auth:sessionStorage.getItem("login"),
                        initial_row: 0,
                        final_row: 10
                    });
                    Swal.fire({
                        title: "El Nuevo Periodo para Pago Comision ha sido registrado correctamente.",
                        icon: "success",
                        confirmButtonText: "OK",
                        confirmButtonColor: '#FEB72B'
                    }); 
                    
                }
            }else{
                Swal.fire({
                    icon:"warning",
                    title:"Cuidado!",
                    text:"Favor de completlar los campos correctamente.",
                    confirmButtonText: "OK",
                    confirmButtonColor: '#FEB72B'
                });
            } 
        } catch (error) {
            console.log(error)
            isLoading.value = false;
            Swal.fire({
                icon:"error",
                title:"Error!",
                text:"Ocurrio un error al momento de guardar los datos.",
                confirmButtonColor:"#FEB72B"
            });
        }
        setIdAttributeToAccepAndCancelButtonSwalFire("button_confirm_ok","")
    }

    async function generateCSV(){
        isLoading.value=true;
        try {
            let _response=await _classCommissions.get({ token_auth:sessionStorage.getItem("login")},"foncabsa/sale_commission_payment_period/export_csv");
            isLoading.value=false;
            if(_response.status==200){
                var base64 = _response.data.response.document_info.file_encodig
                var csvString = atob(base64);
                var universalBOM = "\uFEFF";
                var a = window.document.createElement('a');
                a.setAttribute('href', 'data:text/csv; charset=utf-8,' + encodeURIComponent(universalBOM+csvString));
                a.setAttribute('download', " periodo_para_pago_de_comisiones"+ ".csv");
                window.document.body.appendChild(a);
                a.click();

                Swal.fire({
                    title: "Aviso",
                    text: "El archivo se generó correctamente",
                    icon: "success",
                    confirmButtonColor: '#FEB72B',
                })
            }else{
                Swal.fire({
                    title: "Aviso",
                    text: "No hay información para mostrar",
                    icon: "info",
                    confirmButtonColor: '#FEB72B',
                    
                })
            }
        } catch (error) {
            isLoading.value=false;
            console.log(error)
            Swal.fire({
                icon:"warning",
                title:"Aviso",
                text:"No se encunetran elementos para poder exportar.",
                confirmButtonText: "OK",
                confirmButtonColor: '#FEB72B'
            })
        }
        setIdAttributeToAccepAndCancelButtonSwalFire("button_confirm_ok","")
    }

    function  setIdAttributeToAccepAndCancelButtonSwalFire(idButtonConfirm, idButtonCancel) {
            $('.swal2-confirm').attr('id', idButtonConfirm);
            $('.swal2-cancel').attr('id', idButtonCancel);
    }

    return { getResponseDateStart,_showAddNewPeriod,v$,rulesLoaded,showAddNewPeriod,getResponseDateFinish,saveNewPeriodPayment,
    _listOfPeriodPatment,totalRowQuerry,PaginationRef,getPeriodPayment,hidePagination,isLoading,showEditModal,OpenShowEditModal,v2$,
    CloseShowEditModal,date_Start,date_Finish,getResponseDateStartEdit,getResponseDateFinishEdit,saveEditContent,generateCSV }
  }
};
</script>

<style scoped>
#button_Cancel_edition_period_capture_period{
 border: 2px solid #810042;
    border-radius: 5px;
    color: #810042;
    font-weight: 700;
    outline: 0;
    transition: all 300ms;
}

#button_Cancel_edition_period_capture_period:hover{
    border: 2px solid #810042;
    border-radius: 5px;
    color: white;
    background: #810042;
    font-weight: 700;
    outline: 0;
}

#button_save_edition_period_capture_period{
 border: 2px solid #FEB72B;
    background: #FEB72B;
    border-radius: 5px;
    color: white;
    font-weight: 700;
    outline: 0;
    transition: all 300ms;
}

#button_save_edition_period_capture_period:hover{
    background: #c58400;
    border: 2px solid #c58400;
}

#button_export_report_capture_period{
    border: 2px solid #810042;
    border-radius: 5px;
    color: #810042;
    font-weight: 700;
    outline: 0;
    transition: all 300ms;
}

#button_export_report_capture_period:hover{
    border: 2px solid #810042;
    border-radius: 5px;
    color: white;
    background: #810042;
    font-weight: 700;
    outline: 0;
}

#button_added_period_capture_period{
    border: 2px solid #FEB72B;
    background: #FEB72B;
    border-radius: 5px;
    color: white;
    font-weight: 700;
    outline: 0;
    transition: all 300ms;
}

#button_added_period_capture_period:hover{
    background: #c58400;
    border: 2px solid #c58400;
}

#select_save_new_period_capture_period{
    background: #FEB72B;
    color: white;
    font-weight: 700;
    outline: 0;
    transition: all 300ms;
}

#select_save_new_period_capture_period:hover{
    background: #c58400;
    color: white;
    outline: 0;
}
.icon_edit_record_capture_period{
    border:1px solid #810042;
    border-radius: 5px;
    background-image: url("../../assets/icon-edit.svg") !important;
    background-position: center !important;
    background-size: 20px 20px !important;
    background-repeat: no-repeat !important;
    height: 40px;
    width: 40px;
    transition: all 300ms ;
}

.icon_edit_record_capture_period:hover{
    background-color: #810042;
    background-image: url("../../assets/icon-edit-white.svg") !important;
    background-position: center !important;
    background-size: 20px 20px !important;
    background-repeat: no-repeat !important;
}

.icon_edit_record_capture_period_disable{
    border:1px solid #be96ab;
    border-radius: 5px;
    background-image: url("../../assets/icon-edit-disable.svg") !important;
    background-position: center !important;
    background-size: 20px 20px !important;
    background-repeat: no-repeat !important;
    height: 40px;
    width: 40px;
    cursor: not-allowed;  
}

table {
  border-collapse: collapse;
  width: 100%;
}

th, td {
  text-align: center;
  padding: 10px;
}

tr:nth-child(even){background-color: #ffffff;}

th {
  background-color: #ffffff;
}

table.width200,table.rwd_auto {border:1px solid #810042;width:100%;margin:0 0 50px 0}
    .width200 th,.rwd_auto th {background:#ffffff;text-align:center;padding-top: 20px;padding-bottom: 20px;}
    .width200 tr:last-child td, .rwd_auto tr:last-child td{border:50px}

.frame::-webkit-scrollbar {
  -webkit-appearance: none;
}

.width200 tbody tr { height: 70px;}


@media only screen and (max-width: 1024px)
{
    table.width200, .width200 thead, .width200 tbody, .width200 th, .width200 td, .width200 tr { display: block; border-radius: 10px; }

    .width200 thead tr { position: absolute;top: -9999px;left: -9999px; }

    .width200 tr th:nth-child(5){display: none;}

    .width200 tbody tr { height: auto;}

    .width200 td { border: none;border-bottom: 1px solid #810042; position: relative;padding-left: 40% ;text-align:left }

    .width200 td:before {  position: absolute;top: 0px; left: 6px; width: 45%; padding-right: 10px; }

    .width200 td:nth-of-type(1):before { content: "Nombre del Perido"; }
    .width200 td:nth-of-type(2):before { content: "Fecha Inicio"; }
    .width200 td:nth-of-type(3):before { content: "Fecha Fin"; }
    .width200 td:nth-of-type(4):before { content: ""; }
    .width200 td:nth-of-type(5):before { content: "Creado por"; }
    .width200 td:nth-of-type(6):before { content: "Fecha de registro"; }

    .descarto {display:none;}
    .fontsize {font-size:10px}

    .icon_edit_record_capture_period
    .icon_edit_record_capture_period_disable{
        height: 40px;
        width: 100%;
    }
}

/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-width : 320px) and (max-width : 480px)
{
    .descarto {display:none;}
    
    .icon_edit_record_capture_period,
    .icon_edit_record_capture_period_disable{
        height: 40px;
        width: 100%;
    }
}

/* iPads (portrait and landscape) ----------- */
@media only screen and (min-width: 768px) and (max-width: 1024px)
{
    .descarto {display:none;}
    .fontsize {font-size:10px} 
    .icon_edit_record_capture_period,
    .icon_edit_record_capture_period_disable{
        height: 40px;
        width: 100%;
    }
}

@media only screen and (min-width: 1024px)
{
    .color-cherry-border {border:1px solid #810042}
     .icon_edit_record_capture_period,
     .icon_edit_record_capture_period_disable{
        height: 40px;
        width: 100%;
    }
}


@media (max-width: 300px) {
  .pagination-items {
    height: 20px;
    width: 30px;
  }
   .icon_edit_record_capture_period,
   .icon_edit_record_capture_period_disable{
        height: 40px;
        width: 100%;
    }
}


</style>

<style>
.swal2-container {
  z-index: 100000000000000 !important;
}
</style>