<template>
   <html>
      <body class="" style="background: #FFF">
          <Header/>
          <MenuLateralMobile/>
          <div class="h-screen grid grid-cols-6 grid-rows-12 grid-flow-col">
            <MenuLateral/>
            <div class="bg-white col-span-6 p-10 mb-10">
                <RecordPeriodPaymentCommissions/>
            </div>
          </div>
      </body>
    </html>
</template>

<script>
import RecordPeriodPaymentCommissions from '@/components/CommissionScheme/RecordPeriodPaymentCommissions.vue';
import Header from "@/components/Administrative/Header/Header.vue";
import MenuLateralMobile from '@/components/MenuLateralMobile.vue';
import MenuLateral from '@/components/LateralMenu.vue';
export default {
    components:{
        RecordPeriodPaymentCommissions,
        Header,
        MenuLateralMobile,
        MenuLateral
    },
    setup() {
        
    },
}
</script>