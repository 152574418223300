const host = process.env.VUE_APP_CCAPI
const business = process.env.VUE_APP_BUSINESS_FONCABSA
import api from '@/api/api'

export default class CommissionScheme  {
  dataCommissionSchem = {
    token_auth: "",
    name: "",
    users_id: "",
    //sale_commission_schema_detail_attributes
    //comission_schema_product_attributes
    sale_commission_schema_detail_attributes: ""
  }

  bond_registration = {
    token_auth: "",
    name: "",//"Inscripcion asistida",
    product_name: "",// "Ahorro",
    comission_type: "",// "porcentaje",
    users_id: "",//206,
    payment_frequency: "",// "mensual",
    sale_bonus_schema_details_attributes: {
      //"value" : 20,
      // "minimum_range" : 1,
      // "maximum_range" : 5,
      // "users_id" : 206
    }
  }

  sale_bonus_schema_details_attributes = {
    value: "",// 20,
    minimum_range: "",// 1,
    maximum_range:"",//  5,
    users_id:"",// 206
  }

  dataTableComissions = {
    name: "",
    value: ""
  }

  save = async (commissionScheme, url) => {
    const API_URL = `${host}/${process.env.VUE_APP_BUSINESS_FONCABSA}${url}`;
    const config = {
      headers: {
        "Content-Type": "application/json",
      }
    };
    const params = commissionScheme;

    return await api.httpPost(API_URL, config, params).catch(err => { return err.response });
  }

  get = async (commissionScheme, url) => {
    const apiUrl = `${host}/${process.env.VUE_APP_BUSINESS_FONCABSA}/${url}`;
    const config = {
      headers: {
        "Content-Type": "application/json",
      }
    };
    const params = commissionScheme;
    try {
      return await api.httpGet(apiUrl, config, commissionScheme); 
    } catch (error) {
      throw error.response;
    }
  }  
}